import React, { useState, useEffect, useCallback, Component } from "react";
import {
  View,
  Image,
  StyleSheet,
  ScrollView,
  Alert,
  ActivityIndicator,
  Text,
  ImageBackground,
  Platform
} from "react-native";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import fetchData from "./fetchData";
import { Feather } from "@expo/vector-icons";
import ReportMenu from "./components/ReportMenu";
import { connect } from "react-redux";
import { fetchAllStreams } from "./actions/streamActions";
import { StreamSelectors } from "./reducers/streamReducer";
import fetchReportMetadata from "./fetchReportMetadata";
import PropTypes from "prop-types";

import {
  Table,
  TableWrapper,
  Row,
  Rows,
  Col,
  Cell
} from "react-native-table-component";
import fetchReportData from "./fetchReportMetadata";

export interface Props {
  streams: Array<any>;
  streamsById: any;
  fetchAllStreams(): any;
  isMenuVisible: boolean;
  onMenuPress(): any;
  fetching: boolean;
  error: boolean;
  location: any;
}
export interface State {
  selectedStreamID: string;
  tableHeadTitle: Array<any>;
  reportData: any;
}

function useData() {
  const data = fetchReportData();
  return data;
}

class ReportMetadata extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      selectedStreamID: null,
      tableHeadTitle: [
        "Field Name",
        "Description",
        "Alternative Names",
        "Business Rules",
        "Calculation",
        "Default Value",
        "Field Type",
        "DataSource",
        "Owner",
        "Valid Values"
      ],
      reportData: []
    };
  }

  componentDidMount() {
    this.props.fetchAllStreams();
    const data = useData();
    this.setState({ reportData: data.reportcatalog }, () => {
      //console.log(this.state.reportData, "REPORT DATA");
    });
    console.log("PROPS: ", this.props.location.state.name);
  }

  render() {
    if (this.props.fetching === false && this.props.error === true)
      return (
        <View
          style={{
            flex: 1,
            flexBasis:'auto',
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <View style={styles.errorContainer}>
            <Feather
              style={{
                marginBottom: 15
              }}
              name="alert-circle"
              size={27}
              color="grey"
            />
            <Text
              style={[
                styles.errorMessage,
                {
                  fontWeight: "bold",
                  marginBottom: 15,
                  color: "#e74c3c"
                }
              ]}
            >
              An error occurred
            </Text>
            <Text style={styles.errorMessage}>Could not get streams data</Text>
          </View>
        </View>
      );
    if (this.props.streams.length == 0)
      return (
        <View
          style={{
            flex: 1,
            flexBasis:'auto',
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 650
          }}
        >
          <ActivityIndicator />
        </View>
      );
    return (
      <View style={styles.container}>
        {this.props.isMenuVisible && (
          <ReportMenu
            streams={this.props.streams}
            selectedStreamID={this.state.selectedStreamID}
            setSelectedStreamID={streamId => {
              if (Platform.OS != "web") this.props.onMenuPress();
              this.setState({ selectedStreamID: streamId });
            }}
          />
        )}
        <ScrollView
          style={{ flex: 1,                     flexBasis:'auto',}}
          contentContainerStyle={{ height: "100%" }}
        >
          <View style={{ flex: 1,flexBasis:'auto', padding: 16, paddingTop: 30 }}>
            <Text style={styles.headingLinkTitle}>
              {this.props.location.state.name}
            </Text>
            <Table borderStyle={{ borderWidth: 1, borderColor: "#407cee" }}>
              <Row
                data={this.state.tableHeadTitle}
                style={styles.head}
                textStyle={styles.headText}
                flexArr={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
              />
            </Table>
            <Table borderStyle={{ borderWidth: 1, borderColor: "#407cee" }}>
              {this.state.reportData.map((reportdata, index) => (
                <TableWrapper
                  key={index}
                  style={[
                    styles.wrapper,
                    index % 2 && { backgroundColor: "#F7F6E7" }
                  ]}
                >
                  <Cell
                    data={reportdata.FieldName}
                    textStyle={styles.cellText}
                  />
                  <Cell
                    data={reportdata.Description}
                    textStyle={styles.cellText}
                  />
                  <Cell
                    data={reportdata.AlternativeNames}
                    textStyle={styles.cellText}
                  />
                  <Cell
                    data={reportdata.BusinessRules}
                    textStyle={styles.cellText}
                  />
                  <Cell
                    data={reportdata.Calculation}
                    textStyle={styles.cellText}
                  />
                  <Cell
                    data={reportdata.DefaultValue}
                    textStyle={styles.cellText}
                  />
                  <Cell
                    data={reportdata.DefaultValue}
                    textStyle={styles.cellText}
                  />
                  <Cell
                    data={reportdata.DataSource}
                    textStyle={styles.cellText}
                  />
                  <Cell data={reportdata.Owner} textStyle={styles.cellText} />
                  <Cell
                    data={reportdata.ValidValues}
                    textStyle={styles.cellText}
                  />
                </TableWrapper>
              ))}
            </Table>
          </View>
          <View style={{ paddingTop: 35, paddingBottom: 7 }}>
            <Image
              resizeMode="contain"
              style={{
                flex: 1,
                flexBasis:'auto',
                width: "100%",
                height: 175,
                opacity: 0.75,
                position: "relative"
              }}
              source={require("../assets/wisp.svg")}
            />
          </View>
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    streams: StreamSelectors.getStreams(state.stream) || [],
    streamsById: state.stream.streamEntities,
    error: state.stream.error,
    fetching: state.stream.fetching
  };
};

// export default connect(
//   mapStateToProps,
//   {
//     fetchAllStreams
//   }
// )(ReportMetadata);

export default withRouter(
  connect(
    mapStateToProps,
    { fetchAllStreams }
  )(ReportMetadata)
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexBasis:'auto',
    flexDirection: "row"
  },
  bold: { fontWeight: "bold" },

  menuContainer: {
    width: 225,
    borderRightWidth: 1,
    borderRightColor: "grey",
    paddingTop: 15
  },
  menuItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 15
  },
  menuItemText: {
    fontSize: 16,
    color: "#21428B",
    fontFamily: "Arial"
  },
  menuHeaderContainer: {
    marginTop: 20,
    marginBottom: 10,
    marginHorizontal: 25
  },
  menuItemsHeader: {
    backgroundColor: "#21428B",
    borderRadius: 25,
    color: "#FFFFFF",
    paddingVertical: 5,
    paddingHorizontal: 25,
    fontFamily: "Arial"
  },
  innerItemsHeader: {
    backgroundColor: "#407cee",
    borderRadius: 25,
    color: "#FFFFFF",
    marginBottom: 10,
    paddingVertical: 5,
    paddingHorizontal: 25
  },

  menuItemIcon: {
    width: 45,
    height: 45,
    paddingRight: 5
  },
  streamListItem: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    fontSize: 16
  },
  selectedStreamListItem: {
    backgroundColor: "rgba(0,0,0,.05)"
  },
  userIcon: {
    width: 30,
    height: 30
  },
  reportMenuContainer: {
    width: 270,
    borderRightWidth: 1,
    borderRightColor: "grey",
    paddingTop: 15
  },
  innerContainer: {
    flex: 1,
    flexBasis:'auto',
    //flexDirection: 'column',
    // justifyContent: "center",
    paddingVertical: 150,
    paddingHorizontal: 350
  },
  rowContainer: {
    flexDirection: "row"
  },

  bgImage: {
    zIndex: -1,
    position: "absolute",
    width: "100%",
    height: "100%",
    bottom: 0
  },
  textItem: {
    color: "#407cee",
    fontSize: 25,
    paddingBottom: 20
  },
  bulletItem: {
    color: "#fab130",
    padding: 10,
    fontSize: 15
  },
  selectedItemText: {
    color: "#E57230"
  },
  bottom: {
    flex: 1,
    flexBasis:'auto',
    justifyContent: "flex-end",
    marginBottom: 36
  },
  errorContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 25,
    paddingVertical: 15,
    borderRadius: 5
  },
  errorMessage: {
    color: "#333333",
    fontWeight: "500",
    fontSize: 20
  },
  rightContainer: {
    flex: 1,
    flexBasis:'auto',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  headingLinkTitle: {
    color: "#407cee",
    fontSize: 20,
    paddingBottom: 20
  },
  head: {
    height: 45,
    backgroundColor: "#fab130"
    // borderRadius:10,
    //  borderColor:'red'
  },
  headText: {
    padding: 5,
    textAlign: "center",
    color: "#21428B",
    fontWeight: "bold",
    fontFamily: "Arial"
  },
  wrapper: {
    flexDirection: "row"
  },
  cellText: {
    color: "#21428B",
    marginLeft: 5,
    fontFamily: "Arial",
    fontSize: 12,
    marginBottom: 5
  }
});
