import React, { Component } from "react";
import { connect } from "react-redux";
import {
  StyleSheet,
  ScrollView,
  View,
  Platform,
  ActivityIndicator,
  Image,
  Text,
  ImageBackground,
  Dimensions,
  TouchableOpacity
} from "react-native";
import { TextInput } from "react-native-web";
import StreamInfo, { normalize } from "./components/StreamInfo";
import { fetchAllStreams } from "./actions/streamActions";
import { StreamSelectors } from "./reducers/streamReducer";
import Menu from "./components/Menu";
import { Feather } from "@expo/vector-icons";
import { bool } from "aws-sdk/clients/signer";

export interface Props {
  streams: Array<any>;
  streamsById: any;
  fetchAllStreams(): any;
  isMenuVisible: boolean;
  onMenuPress(): any;
  loginUser(username: string, password: string): any;
  fetching: boolean;
  error: boolean;
}

class ContactUs extends Component<Props, {}> {
  componentDidMount() {
    if (this.props.streams.length === 0) this.props.fetchAllStreams();
  }

  render() {
    const layout = Dimensions.get("window");

    if (this.props.fetching === false && this.props.error === true)
      return (
        <View
          style={{
            flex: 1,
              flexBasis:'auto',
            justifyContent: "center",
            alignItems: "center"
          }}>
          <View style={styles.errorContainer}>
            <Feather
              style={{
                marginBottom: 15
              }}
              name="alert-circle"
              size={27}
              color="grey"
            />
            <Text
              style={[
                styles.errorMessage,
                {
                  fontWeight: "bold",
                  marginBottom: 15,
                  color: "#e74c3c"
                }
              ]}>
              An error occurred
            </Text>
            <Text style={styles.errorMessage}>Could not get streams data</Text>
          </View>
        </View>
      );

    if (this.props.streams.length == 0)
      return (
        <View
          style={{
            flex: 1,
              flexBasis:'auto',
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 650
          }}>
          <ActivityIndicator />
        </View>
      );

    return (
      <View style={styles.container}>
        {this.props.isMenuVisible && <Menu streams={this.props.streams} />}
        <View style={{ flex: 1,                     flexBasis:'auto'}} contentContainerStyle={{ height: "100%" }}>
          <View
            style={{
              paddingHorizontal: layout.width <= 1400 ? 100 : 200,
              paddingTop: 45,
              paddingBottom: 25
            }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center"
              }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 35,
                  color: "#385696",
                  marginBottom: 35,
                  marginRight: 7
                }}>
                Contact
              </Text>
              <Text
                style={{
                  fontSize: 35,
                  color: "#385696",
                  marginBottom: 35,
                  fontWeight: "300"
                }}>
                Us
              </Text>
            </View>

            <TextInput
              autoFocus={true}
              placeholder="E-mail"
              placeholderTextColor="#2d4578"
              underlineColorAndroid="transparent"
              allowFontScaling={true}
              style={{
                fontSize: 17,
                outlineStyle: "none",
                paddingHorizontal: 15,
                marginLeft: 5,
                height: 50,
                borderColor: "#2A7BF6",
                borderWidth: 1
              }}
            />

            <TextInput
              multiline
              numberOfLines={6}
              placeholder="Your Message"
              placeholderTextColor="#2d4578"
              underlineColorAndroid="transparent"
              allowFontScaling={true}
              style={{
                paddingTop: 15,
                marginTop: 25,
                fontSize: 17,
                outlineStyle: "none",
                paddingHorizontal: 15,
                marginLeft: 5,
                borderColor: "#2A7BF6",
                borderWidth: 1
              }}
            />

            <TouchableOpacity
              style={{
                marginTop: 25,
                backgroundColor: "#2A7BF6",
                borderRadius: 15,
                width: 125
              }}>
              <Text
                style={{
                  marginVertical: 5,
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: "bold",
                  color: "#FFF"
                }}>
                Send
              </Text>
            </TouchableOpacity>
          </View>
          <ImageBackground
            resizeMode="contain"
            resizeMethod="resize"
            style={{
              flex: 1,
                flexBasis:'auto',
              width: "100%",
              height: 275,
              position: "absolute",
              bottom: 0
            }}
            source={require("../assets/contact-footer.png")}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    streams: StreamSelectors.getStreams(state.stream) || [],
    streamsById: state.stream.streamEntities,
    error: state.stream.error,
    fetching: state.stream.fetching
  };
};

export default connect(
  mapStateToProps,
  {
    fetchAllStreams
  }
)(ContactUs);

const styles = StyleSheet.create({
  container: {
    flex: 1,
      flexBasis:'auto',
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    minHeight: 650
  },
  bold: {
    fontWeight: "bold"
  },
  streamListItem: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    fontSize: 16
  },
  errorContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 25,
    paddingVertical: 15,
    borderRadius: 5
  },
  errorMessage: {
    color: "#333333",
    fontWeight: "500",
    fontSize: 20
  },
  userIcon: {
    width: 30,
    height: 30
  }
});
