import React, { useState } from 'react';
import {
  View,
  Image,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  Text,
  ScrollView,
  Animated,
  Button
} from 'react-native';
import { Feather } from '@expo/vector-icons';

import VyaireLogo from './VyaireLogo';
import ReportsList from './ReportsList';
import debounce from 'debounce';
import Fuse from 'fuse.js';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import {
  DimensionAware,
  getWindowWidth,
  getWindowHeight
} from 'react-native-dimension-aware';

import PopUpMenu from './PopUpMenu';
import MenuItem from './MenuItem';
import MenuDivider from './MenuDivider';

import { Link } from 'react-router-dom';

const options = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 200,
  keys: ['name']
};

class DropDownMenu extends React.Component<{ currentUser: any }> {
  _menu = null;

  constructor(props) {
    super(props);
    this.state = {
      showing: false
    };
  }

  setMenuRef = ref => {
    this._menu = ref;
    this._menu.hide();
  };

  hideMenu = () => {
    this._menu.hide();
  };
  onLogout = () => {
    this.hideMenu();
    this.props.onLogout();
  };

  showMenu = () => {
    console.log(this.state.showing);
    if (this.state.showing === true) {
      this._menu.hide();
    } else {
      this._menu.show();
    }

    this.setState({ showing: !this.state.showing });
  };

  render() {
    return (
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <PopUpMenu
          onHidden={() => this.setState({ showing: false })}
          ref={this.setMenuRef}
          button={
            <TouchableOpacity onPress={this.showMenu}>
              <Image
                source={require('../../assets/icons/settings.png')}
                style={styles.gearIcon}
              />
            </TouchableOpacity>
          }>
          <MenuItem onPress={this.hideMenu}>
            <View
              style={{
                flex: 1,
                flexBasis: 'auto',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
              <Image
                source={require('../../assets/icons/user.png')}
                style={styles.gearIcon}
              />
              <Text
                style={{
                  color: '#1D4289',
                  fontWeight: 'bold'
                }}>
                {this.props.currentUser.username}
              </Text>
            </View>
          </MenuItem>
          <MenuDivider />
          <MenuItem onPress={this.onLogout}>
            <View
              style={{
                flex: 1,
                flexBasis: 'auto',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
              <Feather
                style={{
                  marginLeft: 8,
                  marginRight: 15,
                  borderRadius: 25,
                  borderColor: 'grey',
                  borderWidth: 1,
                  padding: 5
                }}
                name="log-out"
                size={13}
                color="#1e272e"
              />
              <Text
                style={{
                  color: '#e74c3c'
                }}>
                Logout
              </Text>
            </View>
          </MenuItem>
        </PopUpMenu>
      </View>
    );
  }
}

const CustomMenu = props => {
  const { style, children, layouts, ...other } = props;

  const anchor = {
    width: 4,
    height: 4,
    backgroundColor: 'white',
    elevation: 5
  };

  return (
    <View
      style={[
        style,
        {
          flexBasis: 'auto',
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 2
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,

          elevation: 5,
          backgroundColor: 'transparent',
          alignItems: 'center'
        }
      ]}>
      <View
        {...other}
        style={{
          backgroundColor: 'white'
        }}>
        {children}
      </View>
    </View>
  );
};

function HeaderContent({
  currentUser,
  onMenuIconPress,
  reports,
  width,
  height,
  onLogout
}) {
  const [queryResults, setQueryResults] = useState([]);

  const handleOnChangeText = debounce(text => {
    const _reports = reports.reduce((acc, val) => acc.concat(val), []);
    const fuse = new Fuse(_reports, options);
    setQueryResults(fuse.search(text));
  }, 150);

  return (
    <View
      style={{
        paddingTop: 15,
        zIndex: 1,
        height: 110,
        backgroundColor: '#FFFFFF'
      }}>
      <View
        style={{
          flex: 1,
          flexBasis: 'auto',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        {width <= 560 ? (
          <Link to="/streams">
            <View
              style={[
                styles.logoContainer,
                {
                  paddingLeft: 25,
                  paddingTop: 0,
                  paddingBottom: 0
                }
              ]}>
              <VyaireLogo width={75} viewBox="0 0 165 35" />
              <Image
                source={require('../../assets/logo/insight.png')}
                style={[
                  styles.insightLogo,
                  width <= 892 && { width: 75, marginLeft: 5 }
                ]}
              />
            </View>
          </Link>
        ) : (
          <View />
        )}

        <View
          style={{
            paddingRight: 15,
            flexDirection: 'row'
          }}>
          <Text
            style={{
              fontSize: 16,
              fontFamily: 'Arial',
              fontWeight: 'normal'
            }}>
            Welcome,{' '}
          </Text>
          <Text
            style={{
              fontSize: 16,
              fontFamily: 'Arial',
              fontWeight: 'normal',
              color: '#1D4289'
            }}>
            {currentUser.username}
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.container,
          width <= 892 && { height: 70 },
          width <= 560 && { paddingTop: 0 }
        ]}>
        {width <= 892 && (
          <TouchableOpacity onPress={onMenuIconPress}>
            <Image
              source={require('../../assets/menu.png')}
              style={styles.menuIcon}
            />
          </TouchableOpacity>
        )}
        {width >= 560 && (
          <Link to="/streams">
            <View
              style={[
                styles.logoContainer,
                width <= 892 && {
                  paddingLeft: 0,
                  paddingVertical: 0
                }
              ]}>
              <VyaireLogo
                width={width <= 892 ? 75 : 165}
                viewBox={width <= 892 ? '0 0 165 35' : '0 0 165 44'}
              />
              <Image
                source={require('../../assets/logo/insight.png')}
                style={[
                  styles.insightLogo,
                  width <= 892 && { width: 75, marginLeft: 5 }
                ]}
              />
            </View>
          </Link>
        )}
        <View
          style={{
            flex: 1,
            flexBasis: 'auto',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <View
            style={{
              marginTop: 5,
              flex: 1,
              flexBasis: 'auto',
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: wp('1%'),
              justifyContent: 'flex-end'
            }}>
            <Image
              source={require('../../assets/search.png')}
              style={styles.searchIcon}
            />

            <TextInput
              placeholder="Enter report name"
              underlineColorAndroid="transparent"
              onChangeText={handleOnChangeText}
              allowFontScaling={true}
              style={{
                paddingHorizontal: 15,
                marginLeft: 5,
                flex: 1,
                flexBasis: 'auto',
                maxWidth: 250,
                height: 25,
                borderBottomColor: '#1A428A',
                borderBottomWidth: 1,
                outlineStyle: 'none'
              }}
            />
          </View>
          <DropDownMenu currentUser={currentUser} onLogout={onLogout} />
        </View>

        {queryResults.length > 0 && (
          <ScrollView
            style={{
              zIndex: 2,
              minWidth: '600px',
              position: 'absolute',
              right: 0,
              top: 80,
              backgroundColor: '#FFFFFF',
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,

              elevation: 5
            }}>
            <ReportsList reports={queryResults} />
          </ScrollView>
        )}
      </View>
    </View>
  );
}

export default function Header(props) {
  return (
    <DimensionAware
      render={dimensions => (
        <HeaderContent
          {...{
            ...props,
            width: getWindowWidth(dimensions),
            height: getWindowHeight(dimensions)
          }}
        />
      )}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
    height: 80,
    borderBottomColor: '#d3d3d3',
    borderBottomWidth: 0.5,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    flexWrap: 'wrap'
  },
  logoContainer: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  insightLogo: {
    width: 160,
    height: 45
  },
  searchIcon: {
    marginTop: 5,
    width: 18,
    height: 18,
    marginRight: 2.5
  },
  gearIcon: {
    width: 43,
    height: 43,
    marginTop: 5,
    marginRight: 5,
    backgroundColor: '#FFFFFF'
  },
  menuIcon: {
    width: 24,
    height: 24,
    marginHorizontal: 25
  }
});
