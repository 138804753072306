// Auth actions
export const LOGGED_IN_STATUS_CHANGED = 'LOGGED_IN_STATUS_CHANGED';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const LOGIN_USER = 'LOGIN_USER';
export const IDENTITY_UPDATED = 'IDENTITY_UPDATED';
export const AUTH_FORM_UPDATE = 'AUTH_FORM_UPDATE';
export const LOGOUT = 'LOGOUT';

export const FETCH_BOBJ = 'FETCH_BOBJ_DATA';
export const FETCH_BOBJ_SUCCESS = 'FETCH_BOBJ_SUCCESS';
export const FETCH_BOBJ_FAILED = 'FETCH_BOBJ_FAILED';

export const FETCH_STREAMS = 'FETCH_STREAMS_DATA';
export const FETCH_STREAMS_SUCCESS = 'FETCH_STREAMS_SUCCESS';
export const FETCH_STREAMS_FAILED = 'FETCH_STREAMS_FAILED';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_FAILED = 'AUTH_USER_FAILED';
