import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  View,
  Platform,
  ActivityIndicator,
  Text,
  StyleSheet,
  Image,
  Dimensions,
  Linking
} from 'react-native';
import { Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './Home';
import ContactUs from './ContactUs';
import ReportCatalogue from './ReportCatalogue';
import ReportMetadata from './ReportMetadata';
import * as Font from 'expo-font';
import { StreamSelectors } from './reducers/streamReducer';
import VyaireLogo from './components/VyaireLogo';
import Hyperlink from 'react-native-hyperlink';
import { handleSignOut } from './actions/authAction';
import { MenuProvider } from 'react-native-popup-menu';

export interface Props {
  loggedIn: boolean;
  user: any;
  reports: Array<any>;
  handleSignOut(): any;
}
export interface State {
  isMenuVisible: boolean;
  fontLoaded: boolean;
  width: number;
}

function GetIEVersion() {
  var sAgent = window.navigator.userAgent;
  var Idx = sAgent.indexOf('MSIE');

  // If IE, return version number.
  if (Idx > 0)
    return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)));
  // If IE 11 then look for Updated user agent string.
  else if (!!navigator.userAgent.match(/Trident\/7\./)) return 11;
  else return 0; //It is not IE
}

class App extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isMenuVisible: Platform.OS == 'web',
      fontLoaded: false,
      width: Dimensions.get('window').width
    };
  }

  async componentDidMount() {
    Font.loadAsync({
      'helvetica-neue-thin': require('../assets/fonts/helvetica/HelveticaNeue-Thin.ttf')
    });

    this.setState({ fontLoaded: true });
  }

  handleMenuIconClick = () =>
    this.setState({ isMenuVisible: !this.state.isMenuVisible });

  render() {
    const { isMenuVisible } = this.state;
    const { loggedIn } = this.props;

    if (!loggedIn) {
      return (
        <View
          style={{
            flex: 1,
            flexBasis: 'auto',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <ActivityIndicator />
        </View>
      );
    }

    return (
      <View
        onLayout={this._handleLayout}
        style={{
          flex: 1,
          flexBasis: 'auto',
          paddingHorizontal: this.state.width <= 768 ? 25 : 70,
          paddingVertical: 40,
          shadowColor: '#000',
          backgroundColor: '#f0f0f0'
        }}>
        <View
          style={{
            flex: 1,
            flexBasis: 'auto',
            backgroundColor: '#FFFFFF',
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 2
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,

            elevation: 5
          }}>
          <Header
            currentUser={this.props.user}
            onMenuIconPress={this.handleMenuIconClick}
            reports={this.props.reports}
            onLogout={this.props.handleSignOut}
          />

          {this.state.fontLoaded ? (
            <Switch>
              <Route
                path="/streams/:streamId"
                render={props => (
                  <Home
                    {...props}
                    isMenuVisible={isMenuVisible}
                    onMenuPress={this.handleMenuIconClick}
                  />
                )}
              />

              <Route
                exact
                path="/streams"
                render={props => (
                  <Home
                    {...props}
                    isMenuVisible={isMenuVisible}
                    onMenuPress={this.handleMenuIconClick}
                  />
                )}
              />
              {/* <Route path="/reportMetadata" component={ReportMetadata} /> */}

              <Route
                exact
                path="/contact-us"
                render={props => (
                  <ContactUs
                    {...props}
                    isMenuVisible={isMenuVisible}
                    onMenuPress={this.handleMenuIconClick}
                  />
                )}
              />

              <Route
                exact
                path="/reportMetadata"
                render={props => (
                  <ReportMetadata
                    {...props}
                    isMenuVisible={isMenuVisible}
                    onMenuPress={this.handleMenuIconClick}
                  />
                )}
              />

              <Route
                exact
                path="/reportCatalog"
                render={props => (
                  <ReportCatalogue
                    {...props}
                    isMenuVisible={isMenuVisible}
                    onMenuPress={this.handleMenuIconClick}
                  />
                )}
              />
            </Switch>
          ) : (
            <ActivityIndicator size="large" />
          )}
        </View>

        <View
          style={{
            flexDirection: 'row',
            justifyContent:
              this.state.width <= 892 ? 'center' : 'space-between',
            marginTop: 25,
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
          <Hyperlink
            linkStyle={{ color: '#007bff' }}
            onPress={url => (window.location.href = url)}>
            <Text>
              Need help? Call 1-872-757-0400 or email ConnectIT@vyaire.com
            </Text>
          </Hyperlink>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <View
              style={{
                borderStyle: 'solid',
                borderLeftWidth: 1,
                borderLeftColor: '#000000',
                height: 15,
                paddingRight: 15
              }}
            />
            <View style={{ paddingRight: 12 }}>
              <VyaireLogo width={75} height={45} viewBox="0 0 165 35" />
            </View>
            <Hyperlink
              linkStyle={{ color: '#007bff' }}
              onPress={url => window.open(url, '_blank')}>
              <Text
                style={{
                  paddingRight: 15,
                  fontSize: 16,
                  marginBottom: 3
                }}>
                vyaire.com
              </Text>
            </Hyperlink>
            <View
              style={{
                borderStyle: 'solid',
                borderLeftWidth: 1,
                borderLeftColor: '#000000',
                height: 15,
                paddingRight: 25
              }}
            />
            <Text>Vyaire Medical, Inc. All Rights Registered</Text>
          </View>
        </View>
      </View>
    );
  }

  _handleLayout = ({ nativeEvent }) => {
    const { height, width } = nativeEvent.layout;
    this.setState(() => ({ width, isMenuVisible: width > 892 }));
  };
}

const styles = StyleSheet.create({
  insightLogo: {
    width: 160,
    height: 60
  },
  footerText: {
    paddingHorizontal: 25
  }
});

const mapStateToProps = state => {
  return {
    loggedIn: state.auth.loggedIn,
    user: state.auth.user,
    reports: StreamSelectors.getReports(state.stream) || []
  };
};

export default connect(
  mapStateToProps,
  { handleSignOut }
)(App);
