import React, { useCallback } from "react";
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
  PixelRatio
} from "react-native";

import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router";
import {
  DimensionAware,
  getWindowWidth,
  getWindowHeight
} from "react-native-dimension-aware";

function StreamListItem({ stream, isSelected }) {
  return (
    <TouchableOpacity>
      <View
        style={[styles.menuItem, isSelected && styles.selectedStreamListItem]}>
        <Image
          style={{ width: 35, height: 35, paddingRight: 5 }}
          source={{ uri: stream.stream_icon }}
        />
        <Text
          style={[
            styles.menuItemText,
            isSelected && styles.selectedStreamListItemText
          ]}>
          {stream.name}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

export function MenuContent({ streams, width, height }) {
  const { streamId } = useParams();
  let location = useLocation();

  return (
    <View style={width <= 892 && styles.overlay}>
      <View
        style={[
          styles.menuContainer,
          width <= 892 && {
            zIndex: 1,
            position: "absolute",
            width: 245
          }
        ]}>
        <ScrollView style={{ flex: 1,                    flexBasis:'auto', }}>
          <View>
            <View style={[styles.menuItem, styles.menuHeaderContainer]}>
              <Text
                style={[
                  styles.menuItemText,
                  styles.bold,
                  styles.menuItemsHeader
                ]}>
                Personal
              </Text>
            </View>

            <View style={[styles.menuItem]}>
              <Image
                source={require("../../assets/icons/favourites.png")}
                style={styles.menuItemIcon}
              />
              <Text style={styles.menuItemText}>Saved Reports</Text>
            </View>
            <View style={[styles.menuItem]}>
              <Image
                source={require("../../assets/icons/share.png")}
                style={styles.menuItemIcon}
              />
              <Text style={styles.menuItemText}>Share</Text>
            </View>
          </View>

          <View style={[styles.menuItem, styles.menuHeaderContainer]}>
            <Text
              style={[
                styles.menuItemText,
                styles.bold,
                styles.menuItemsHeader
              ]}>
              Subject Area
            </Text>
          </View>
          {streams.map(stream => (
            <Link
              key={stream.id}
              to={`/streams/${stream.id}`}
              style={{
                textDecoration: "none",
                color: "#FFFFFF"
              }}>
              <StreamListItem
                stream={stream}
                isSelected={stream.id === streamId}
              />
            </Link>
          ))}

          <View style={[styles.menuItem, styles.menuHeaderContainer]}>
            <Text
              style={[
                styles.menuItemText,
                styles.bold,
                styles.menuItemsHeader
              ]}>
              Help
            </Text>
          </View>
          <View style={[styles.menuItem]}>
            <Image
              source={require("../../assets/icons/report-catalogue.png")}
              style={styles.menuItemIcon}
            />
            <Link
              to="/reportCatalog"
              style={{
                textDecoration: "none",
                color: "#FFFFFF"
              }}>
              <Text style={styles.menuItemText}>Report Catalogue</Text>
            </Link>
          </View>
          <View style={[styles.menuItem]}>
            <Image
              source={require("../../assets/icons/data-refresh.png")}
              style={styles.menuItemIcon}
            />
            <Text style={styles.menuItemText}>Data Refresh Schedule</Text>
          </View>
          <View
            style={[
              styles.menuItem,
              location.pathname === "/contact-us" &&
                styles.selectedStreamListItem
            ]}>
            <Image
              source={require("../../assets/icons/contact.png")}
              style={styles.menuItemIcon}
            />
            <Link
              to="/contact-us"
              style={{
                textDecoration: "none",
                color: "#FFFFFF"
              }}>
              <Text
                style={[
                  styles.menuItemText,
                  location.pathname === "/contact-us" &&
                    styles.selectedStreamListItemText
                ]}>
                Contact Us
              </Text>
            </Link>
          </View>
        </ScrollView>
      </View>
    </View>
  );
}

const Menu = props => {
  return (
    <DimensionAware
      render={dimensions => (
        <MenuContent
          {...{
            ...props,
            width: getWindowWidth(dimensions),
            height: getWindowHeight(dimensions)
          }}
        />
      )}
    />
  );
};

export default Menu;

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("window");

// based on iphone 5s's scale
const scale = SCREEN_WIDTH / 320;

export function normalize(size) {
  const newSize = size * scale;
  if (Platform.OS === "ios") {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  } else {
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
  }
}

const styles = StyleSheet.create({
  bold: { fontWeight: "bold" },
  overlay: {
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.1)",
    zIndex: 1,
    width: "100%"
  },
  menuContainer: {
    height: "100%",
    backgroundColor: "#FFFFFF",
    width: 240,
    paddingTop: 10,
    paddingBottom: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 0
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,

    elevation: 1
  },
  menuItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 15
  },
  menuItemText: {
    fontSize: 16,
    color: "#1D4289",
    fontFamily: "Arial",
    fontWeight: "normal"
  },
  menuHeaderContainer: {
    marginTop: 20,
    marginBottom: 10,
    marginHorizontal: 25
  },
  menuItemsHeader: {
    backgroundColor: "#1D4289",
    borderRadius: 25,
    color: "#FFFFFF",
    paddingVertical: 5,
    paddingHorizontal: 25,
    fontFamily: "Arial"
  },
  menuItemIcon: {
    width: 35,
    height: 35,
    paddingRight: 5
  },
  selectedStreamListItem: {
    borderLeftColor: "#6ED2F6",
    borderLeftWidth: 3,
    borderRadius: 2
  },
  selectedStreamListItemText: {
    color: "#E57230"
  }
});
