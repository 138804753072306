const mockReportCatalogueData = {
  ReportCatalogue: [
    {
      SubjectArea: "Order Management",
      FieldName: "Sales Doc Date",
      Description: "Sales Document Created Date",
      AlternativeNames: "Order Date",
      BusinessRules: "N/A",
      Calculation: "N/A",
      DefaultValue: "10/7/2019",
      FieldDataType: "Date",
      DataSource: "Open Order Data Mart",
      Owner: "Bowei Oki",
      ValidValues: "12/31/2019"
    },
    {
      SubjectArea: "Order Management",
      FieldName: "Sold-to",
      Description: "Sold-to Customer Number",
      AlternativeNames: "Customer Number",
      BusinessRules: "Primary Customer Number",
      Calculation: "N/A",
      DefaultValue: "10/7/2019",
      FieldDataType: "Character",
      DataSource: "Open Order Data Mart",
      Owner: "Bowei Oki",
      ValidValues: "0000001456"
    },
    {
      SubjectArea: "Order Management",
      FieldName: "Sold-to Name",
      Description: "Sold-to Customer Number",
      AlternativeNames: "Customer Number",
      BusinessRules: "Primary Customer Number",
      Calculation: "N/A",
      DefaultValue: "10/7/2019",
      FieldDataType: "Character",
      DataSource: "Open Order Data Mart",
      Owner: "Bowei Oki",
      ValidValues: "Alexian Hospital"
    },
    {
      SubjectArea: "Order Management",
      FieldName: "RNS Qty BUoM",
      Description: "Release Not Shipped in Base Unit of Measure",
      AlternativeNames: "RNS Qty",
      BusinessRules:
        "Delivery Quanity of Order Lines with Delivery created but still in the warehouse dock.",
      Calculation: "Order Quanity- Delivered Quantity",
      DefaultValue: "0",
      FieldDataType: "Number",
      DataSource: "Open Order Data Mart",
      Owner: "Bowei Oki",
      ValidValues: "10000"
    },
    {
      SubjectArea: "Order Management",
      FieldName: "RNS Qty BUoM",
      Description: "Release Not Shipped in Base Unit of Measure",
      AlternativeNames: "RNS Qty",
      BusinessRules:
        "Delivery Value in USE of Order Lines with Delivery created but still in the warehouse dock.",
      Calculation: "Order Quanity- Delivered Quantity",
      DefaultValue: "0",
      FieldDataType: "Number",
      DataSource: "Open Order Data Mart",
      Owner: "Bowei Oki",
      ValidValues: "10000"
    }
  ]
};

function convertCommon(json) {
  return {
    reportcatalog: json.ReportCatalogue.map(reportdata => ({
      SubjectArea: reportdata.SubjectArea,
      Description: reportdata.Description,
      FieldName: reportdata.FieldName,
      AlternativeNames: reportdata.AlternativeNames,
      BusinessRules: reportdata.BusinessRules,
      Calculation: reportdata.Calculation,
      DefaultValue: reportdata.DefaultValue,
      DataSource: reportdata.DataSource,
      Owner: reportdata.Owner,
      ValidValues: reportdata.ValidValues
    }))
  };
}

function fetchReportCatalogueData() {
  console.log("mockReportCatalogueData", { mockReportCatalogueData });
  return convertCommon(mockReportCatalogueData);
}
export default function fetchReportData() {
  const data = fetchReportCatalogueData();
  return data;
}
