import React, { useCallback } from "react";
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
  PixelRatio
} from "react-native";

import { Link } from "react-router-dom";

import {
  DimensionAware,
  getWindowWidth,
  getWindowHeight
} from "react-native-dimension-aware";

function StreamListItem({ stream, isSelected, setSelectedStreamID }) {
  const handlePress = useCallback(() => {
    setSelectedStreamID(stream.id);
  }, [setSelectedStreamID, stream.id]);
  return (
    <TouchableOpacity onPress={handlePress}>
      <View
        style={[styles.menuItem, isSelected && styles.selectedStreamListItem]}
      >
        <Image
          style={{ width: 35, height: 35, paddingRight: 5 }}
          source={{ uri: stream.stream_icon }}
        />
        <Text
          style={[
            styles.menuItemText,
            isSelected && styles.selectedStreamListItemText
          ]}
        >
          {stream.name}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

export function MenuContent({
  streams,
  selectedStreamID,
  setSelectedStreamID,
  width,
  height
}) {
  return (
    <View style={width <= 892 && styles.overlay}>
      <View
        style={[
          styles.menuContainer,
          width <= 892 && {
            zIndex: 1,
            position: "absolute",
            width: 245
          }
        ]}
      >
        <ScrollView style={{ flex: 1,                     flexBasis:'auto' }}>
          <View style={[styles.menuItem, styles.menuHeaderContainer]}>
            <Text
              style={[styles.menuItemText, styles.bold, styles.menuItemsHeader]}
            >
              <Link
                to="/reportCatalog"
                style={{ textDecoration: "none", color: "#FFFFFF" }}
              >
                Report Catalog
              </Link>
            </Text>
          </View>
          {streams.map(stream => (
            <StreamListItem
              key={stream.id}
              stream={stream}
              isSelected={stream.id === selectedStreamID}
              setSelectedStreamID={setSelectedStreamID}
            />
          ))}
          <View style={[styles.menuItem, styles.reportMenuContainer]}>
            <Text
              style={[
                styles.menuItemText,
                styles.bold,
                styles.innerItemsHeader
              ]}
            >
              Request Access
            </Text>
          </View>
        </ScrollView>
      </View>
    </View>
  );
}

const Menu = props => (
  <DimensionAware
    render={dimensions => (
      <MenuContent
        {...{
          ...props,
          width: getWindowWidth(dimensions),
          height: getWindowHeight(dimensions)
        }}
      />
    )}
  />
);

export default Menu;

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("window");

// based on iphone 5s's scale
const scale = SCREEN_WIDTH / 320;

export function normalize(size) {
  const newSize = size * scale;
  if (Platform.OS === "ios") {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  } else {
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
  }
}

const styles = StyleSheet.create({
  bold: { fontWeight: "bold" },
  overlay: {
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.1)",
    zIndex: 1,
    width: "100%"
  },
  menuContainer: {
    height: "100%",
    backgroundColor: "#FFFFFF",
    width: 240,
    paddingTop: 10,
    paddingBottom: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 0
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,

    elevation: 1
  },
  menuItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 15
  },
  menuItemText: {
    fontSize: 16,
    color: "#1D4289",
    fontFamily: "Arial",
    fontWeight: "normal"
  },
  menuHeaderContainer: {
    marginTop: 20,
    marginBottom: 10,
    marginHorizontal: 25
  },
  menuItemsHeader: {
    backgroundColor: "#1D4289",
    borderRadius: 25,
    color: "#FFFFFF",
    paddingVertical: 5,
    paddingHorizontal: 25,
    fontFamily: "Arial"
  },
  menuItemIcon: {
    width: 35,
    height: 35,
    paddingRight: 5
  },
  selectedStreamListItem: {
    borderLeftColor: "#6ED2F6",
    borderLeftWidth: 3,
    borderRadius: 2
  },
  selectedStreamListItemText: {
    color: "#E57230"
  },
  reportMenuContainer: {
    width: 270,
    borderRightWidth: 1,
    borderRightColor: "grey",
    marginTop: 20,
    marginBottom: 10
  },
  innerItemsHeader: {
    backgroundColor: "#407cee",
    borderRadius: 25,
    color: "#FFFFFF",
    marginBottom: 10,
    paddingVertical: 5,
    paddingHorizontal: 25
  }
});
