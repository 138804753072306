import React, { Component } from 'react';
import { Text, View, Image } from 'react-native';
import AppLink from './AppLink';

const ReportsList = ({ reports }) => (
    <View
        style={{
            flex: 1,
            flexBasis:'auto',
            width: '100%',
            flexDirection: 'column',
            marginTop: 10,
        }}>
        {reports.map(report => (
            <AppLink app={report} key={report.id}>
                <View
                    style={{
                        flex: 1,
                        flexBasis:'auto',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingTop: 8,
                        paddingBottom: 13,
                        paddingHorizontal: 15,
                        borderBottomColor: '#b7b7b79c',
                        borderBottomWidth: 1,
                        flexWrap: 'wrap',
                        width: '100%',
                    }}>
                    <Image
                        source={{ uri: report.imageUri }}
                        style={{
                            width: 60,
                            height: 60,
                            backgroundColor: 'rgba(0,0,0,.3)',
                            marginRight: 15,
                        }}
                    />
                    <Text
                        style={{
                            fontSize: 14,
                            flexWrap: 'wrap',
                            fontWeight: 'bold',
                        }}>
                        {report.name}
                    </Text>
                </View>
            </AppLink>
        ))}
    </View>
);

export default ReportsList;
