import React, { useState, useContext } from 'react';
import {
  Text,
  StyleSheet,
  View,
  Button,
  Image,
  TouchableOpacity,
  Platform,
  Linking,
  ImageBackground,
  Dimensions,
  PixelRatio,
  FlatList
} from 'react-native';
import { Feather } from '@expo/vector-icons';
import { BobJTokenContext } from './BobjTokenContext';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import AppLink from './AppLink';

Image.prefetch(require('../../assets/image-placeholder.jpg'));

function GetIEVersion() {
  var sAgent = window.navigator.userAgent;
  var Idx = sAgent.indexOf('MSIE');

  // If IE, return version number.
  if (Idx > 0)
    return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)));
  // If IE 11 then look for Updated user agent string.
  else if (!!navigator.userAgent.match(/Trident\/7\./)) return 11;
  else return 0; //It is not IE
}

function ListTypeIcon({ name, isSelected, setSelectedViewType }) {
  console.log(Platform);
  return (
    <TouchableOpacity
      onPress={() => setSelectedViewType(name)}
      style={{ padding: 2, flex: 1, flexBasis: 'auto' }}>
      {GetIEVersion() === 0 ? (
        <Feather name={name} size={16} color={isSelected ? 'blue' : 'grey'} />
      ) : (
        <Image
          resizeMode="contain"
          resizeMethod="scale"
          source={require(`../../assets/icons/${name}_icon_${
            isSelected ? 'blue' : 'grey'
          }.png`)}
          style={{
            height: 16,
            width: 16
          }}
        />
      )}
    </TouchableOpacity>
  );
}

function GridContainer({ children }) {
  return <View style={styles.gridContainer}>{children}</View>;
}

function ListContainer({ children }) {
  return <View style={styles.listContainer}>{children}</View>;
}

function ExternalLink({ href, children }) {
  if (!href) {
    return children;
  }

  const handleClick = () => Linking.openURL(href);

  if (Platform.OS == 'web')
    return (
      <a style={{ textDecorationLine: 'none' }} target="_blank" href={href}>
        {children}
      </a>
    );
  else
    return (
      <TouchableOpacity onPress={handleClick}>{children}</TouchableOpacity>
    );
}

function getTextWidth(text, font) {
  var canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'));
  var context = canvas.getContext('2d');
  context.font = font;
  var metrics = context.measureText(text);
  return metrics.width;
}

const PlaceHolderImage = ({ app }) => {
  const layout = Dimensions.get('window');

  let textWidth = layout.width < 414 ? 145 : 185;

  let fontSize = Math.sqrt((textWidth * 35) / app.name.length);
  let lettersInLine = textWidth / fontSize;
  let lines = app.name.length / lettersInLine;

  return (
    <ImageBackground
      resizeMode="contain"
      resizeMethod="scale"
      source={require('../../assets/image-placeholder.jpg')}
      style={{
        width: '100%',
        height: 200,
        alignItems: 'center'
      }}>
      <View
        style={{
          flexShrink: 1,
          flexDirection: 'row',
          position: 'relative',
          width: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#20428A',

          maxWidth: 205
        }}>
        <Text
          style={{
            flex: 1,
            flexWrap: 'wrap',
            paddingHorizontal: 5,
            paddingTop: 5,
            paddingBottom: 5,
            minHeight: 35,
            textAlign: 'center',
            color: '#FFF',
            fontSize: fontSize > 15 ? 16 : fontSize,
            fontFamily: 'Arial',
            borderBottomWidth: 0
          }}>
          {app.name}
        </Text>
      </View>
    </ImageBackground>
  );
};

function GridItem({ app }) {
  const [imageError, setError] = useState(false);

  const layout = Dimensions.get('window');
  return (
    <View
      style={{
        paddingTop: 25,
        paddingHorizontal: layout.width <= 892 ? normalize(6) : normalize(5),
        width: Platform.OS == 'web' ? '33%' : '40%',
        minWidth: layout.width < 414 ? 145 : 185,
        maxWidth: 275
      }}>
      <AppLink app={app}>
        <View
          style={{
            opacity: app.reportUri === '' ? 0.3 : 1
          }}>
          {app.imageUri === '' || imageError === true ? (
            <PlaceHolderImage app={app} />
          ) : (
            <Image
              resizeMode="contain"
              resizeMethod="scale"
              source={{ uri: app.imageUri }}
              style={{
                height: 200
              }}
              onError={e => setError(true)}
            />
          )}
        </View>
      </AppLink>
    </View>
  );
}

function ListItem({ app }) {
  const [imageError, setError] = useState(false);

  return (
    <AppLink app={app}>
      <View
        style={{
          margin: 10,
          flexDirection: 'row',
          alignItems: 'center'
        }}>
        <View>
          {app.imageUri === '' || imageError === true ? (
            <Image
              resizeMode="contain"
              source={require('../../assets/image-placeholder.jpg')}
              style={{
                height: 65,
                width: 65
              }}
            />
          ) : (
            <Image
              resizeMode="contain"
              source={{ uri: app.imageUri }}
              style={{
                height: 65,
                width: 65
              }}
              onError={e => setError(true)}
            />
          )}
        </View>
        <View style={{ paddingVertical: 10, paddingHorizontal: 10 }}>
          <Text style={{ fontSize: 18 }}>{app.name}</Text>
        </View>
      </View>
    </AppLink>
  );
}

function AppList({ viewType, apps }) {
  const Item = viewType === 'grid' ? GridItem : ListItem;

  const layout = Dimensions.get('window');
  let numColumns = layout.width <= 892 ? 2 : 3;
  let horizontal = layout.width <= 892 ? 'h' : 'v';

  if (viewType === 'grid')
    return (
      <FlatList
        key={horizontal}
        data={apps}
        columnWrapperStyle={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          paddingHorizontal: Platform.OS == 'web' ? wp('1%') : 0
        }}
        renderItem={item => {
          const app = item.item;
          return <Item key={app.id} app={app} />;
        }}
        numColumns={numColumns}
        keyExtractor={(item, index) => index.toString()}
      />
    );
  else
    return (
      <ListContainer>
        {apps.map(app => (
          <Item key={app.id} app={app} />
        ))}
      </ListContainer>
    );
}

function TokenRefetcher({ bobjToken }) {
  if (bobjToken.token) {
    return null;
  } else {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'baseline',
          paddingHorizontal: 20
        }}>
        <Text>BobJ token is not fetched </Text>
        <Button title="refetch token" onPress={bobjToken.refetchToken} />
      </View>
    );
  }
}

function SelectedStreamInfo({ stream }) {
  const [selectedViewType, setSelectedViewType] = useState('grid');
  const bobjToken = useContext(BobJTokenContext);
  return (
    <View>
      <View
        style={{
          flex: 1,
          flexBasis: 'auto',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <View style={{}} />
        <View
          style={{
            padding: 20,
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
          <ListTypeIcon
            name="grid"
            isSelected={selectedViewType === 'grid'}
            setSelectedViewType={setSelectedViewType}
          />
          <ListTypeIcon
            name="list"
            isSelected={selectedViewType === 'list'}
            setSelectedViewType={setSelectedViewType}
          />
        </View>
      </View>

      <View>
        <Text style={styles.header}>{stream.name}</Text>
        <AppList apps={stream.reports} viewType={selectedViewType} />
      </View>
    </View>
  );
}

export default function StreamInfo({ stream }) {
  if (!stream) {
    return (
      <View style={styles.unselectedContainer}>
        <Text>Select a stream to get started</Text>
      </View>
    );
  } else {
    return <SelectedStreamInfo stream={stream} />;
  }
}

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');

// based on iphone 5s's scale
const scale = SCREEN_WIDTH / 320;

export function normalize(size) {
  const newSize = size * scale;
  if (Platform.OS === 'ios') {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  } else {
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
  }
}

const styles = StyleSheet.create({
  unselectedContainer: {
    flex: 1,
    flexBasis: 'auto',
    flexShrink: 1,
    paddingVertical: 100,
    paddingHorizontal: 50,
    alignItems: 'center'
  },

  header: {
    fontFamily: 'helvetica-neue-thin',
    color: '#1D4289',
    textAlign: 'center',
    padding: 20,
    fontSize: Platform.OS != 'web' ? normalize(30) : 50
  },

  gridContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingHorizontal: Platform.OS == 'web' ? wp('1%') : 0
  },
  listContainer: {
    padding: 10,
    flexDirection: 'column',
    flexWrap: 'wrap',
    paddingHorizontal: wp('5%')
  },
  greyText: {
    color: 'grey'
  }
});
