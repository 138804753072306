import "@babel/polyfill";
import { AppRegistry, Platform } from "react-native";
import App from "./App";
const appName = "Vyaire Insight";

import 'es6-promise';
import 'isomorphic-fetch';

window.addEventListener("unhandledrejection", function (event) {
  console.warn("WARNING: Unhandled promise rejection. Reason: " + event.reason, event);
});

AppRegistry.registerComponent(appName, () => App);

if (Platform.OS === "web") {
  window.LOG_LEVEL = 'DEBUG';

  console.log(appName);
  AppRegistry.runApplication(appName, {
    rootTag: document.getElementById("root")
  });
}
