import * as log from 'loglevel';

import {
    FETCH_STREAMS,
    FETCH_STREAMS_SUCCESS,
    FETCH_STREAMS_FAILED,
} from '../actions/types';

export const initialState = {
    streamEntities: {},
    streamIds: [],
    error: null,
    fetching: false,
};

/* ------------- SELECTORS ------------- */
export const StreamSelectors = {
    getStreams: state => state.streamIds.map(id => state.streamEntities[id]),
    getReports: state =>
        state.streamIds.map(id => [...state.streamEntities[id].reports]),
};

/* ------------- REDUCER ------------- */

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STREAMS:
            return {
                ...state,
                fetching: true,
            };
        case FETCH_STREAMS_SUCCESS:
            return {
                ...state,
                fetching: false,
                streamEntities: action.entities.streams,
                streamIds: action.result,
            };
        case FETCH_STREAMS_FAILED:
            return {
                ...state,
                fetching: false,
                error: true,
            };
        default:
            return state;
    }
};
