import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import logger from "redux-logger";

import rootReducer from "../reducers/index";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(ReduxThunk,logger))
);

export default store;
