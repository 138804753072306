import * as log from "loglevel";
import { normalize, schema } from "normalizr";
import Amplify, { API, Auth } from "aws-amplify";
import { Image } from "react-native";

import {
  FETCH_STREAMS,
  FETCH_STREAMS_SUCCESS,
  FETCH,
  FETCH_STREAMS_FAILED
} from "./types";
import { fetchQlikData, fetchBobjData } from "../mockData/index";
const StreamSchema = new schema.Entity("streams", {});
const StreamListSchema = new schema.Array(StreamSchema);
import * as ApiGateway from "../services/api-gateway";
import { Config } from "../services/aws-config";

const uri = {
  getQsStreams: 'https://5wxx0bghlf.execute-api.us-east-2.amazonaws.com/Dev/',
  getBobjToken: "https://cxi48xn1me.execute-api.us-east-2.amazonaws.com/Dev/",
  getBobjStreams: "https://t7xbnx48p4.execute-api.us-east-2.amazonaws.com/Dev/"
};

const postArgs = {
  method: "POST",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  body: '"niyaz.kamookagath"'
};

function convertQSToCommon(json) {
  return {
    userID: json.users.user_id,
    userName: json.users.user_name,
    streams: json.qlikstreams.map(stream => ({
      id: stream.stream_id,
      name: stream.stream_name,
      stream_url: stream.stream_url,
      stream_icon: stream.streamicons3url,
      streamType: "QlikSense",
      reports: stream.qlikApps.map(app => {
        return {
          id: app.app_id,
          name: app.app_name,
          imageUri: app.apps3imagepath,
          reportUri: app.app_url,
          Sheet: app.Sheet
        };
      })
    }))
  };
}

function convertBobJToCommon(json) {
  return {
    userID: json.users.user_id,
    userName: json.users.user_name,
    streams: json.bobjCategories.map(category => ({
      id: category.category_id,
      name: category.category_name,
      stream_icon: category.streamicons3url,
      streamDescription: category.category_description,
      streamType: "BobJ",
      reports: category.bobjDOcumentsList.map(document => ({
        id: document.document_id,
        name: document.document_name,
        imageUri: document.apps3sampleimagepath,
        reportUri: document.documentsuri.replace(
          "<document_id>",
          encodeURIComponent(document.document_id)
        ),
        Sheet: { sheetURL: "" }
      }))
    }))
  };
}

async function fetchQSData() {
  const userSession = await Auth.currentSession();

  const res = await fetch(uri.getQsStreams, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: userSession.idToken.jwtToken
    },
    body: '"niyaz.kamookagath"'
  });

  return await res.json();
}

async function fetchBobJData() {
  const userSession = await Auth.currentSession();

  const res = await fetch(uri.getBobjStreams, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: userSession.idToken.jwtToken
    },
    body: '"niyaz.kamookagath"'
  });

  return await res.json();
}

export const fetchAllStreams = () => async dispatch => {
  dispatch({ type: FETCH_STREAMS });

  // Promise.all([fetchQSData(), fetchBobjData()]).then(([qsData, bobJData]) => {
  //   const convertedQS = convertQSToCommon(qsData);
  //   const convertedBobJ = convertBobJToCommon(bobJData);
  //
  //   const streams = [...convertedQS.streams, ...convertedBobJ.streams];
  //
  //   dispatch({
  //     type: FETCH_STREAMS_SUCCESS,
  //     ...normalize(streams, StreamListSchema)
  //   });
  // });

  fetchQSData()
    .then(qsData => {
      const convertedQS = convertQSToCommon(qsData);

      const streams = [...convertedQS.streams];

      dispatch({
        type: FETCH_STREAMS_SUCCESS,
        ...normalize(streams, StreamListSchema)
      });
    })
    .catch(() => dispatch({ type: FETCH_STREAMS_FAILED }));
};
