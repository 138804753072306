import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  StyleSheet,
  ScrollView,
  View,
  Platform,
  ActivityIndicator,
  Image,
  Text
} from 'react-native';
import StreamInfo from './components/StreamInfo';
import { fetchAllStreams } from './actions/streamActions';
import { StreamSelectors } from './reducers/streamReducer';
import Menu from './components/Menu';
import { Feather } from '@expo/vector-icons';
import { bool } from 'aws-sdk/clients/signer';
import { withRouter } from 'react-router';
import { MenuProvider } from 'react-native-popup-menu';

export interface Props {
  streams: Array<any>;
  streamsById: any;
  fetchAllStreams(): any;
  isMenuVisible: boolean;
  onMenuPress(): any;
  loginUser(username: string, password: string): any;
  fetching: boolean;
  error: boolean;
}

class Home extends Component<Props, {}> {
  componentDidMount() {
    if (this.props.streams.length === 0) this.props.fetchAllStreams();
  }

  render() {
    const { streamId } = this.props.match.params;
    if (this.props.fetching === false && this.props.error === true)
      return (
        <View
          style={{
            flex: 1,
            flexBasis: 'auto',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <View style={styles.errorContainer}>
            <Feather
              style={{
                marginBottom: 15
              }}
              name="alert-circle"
              size={27}
              color="grey"
            />
            <Text
              style={[
                styles.errorMessage,
                {
                  fontWeight: 'bold',
                  marginBottom: 15,
                  color: '#e74c3c'
                }
              ]}>
              An error occurred
            </Text>
            <Text style={styles.errorMessage}>Could not get streams data</Text>
          </View>
        </View>
      );

    if (this.props.streams.length == 0)
      return (
        <View
          style={{
            flex: 1,
            flexBasis: 'auto',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 650
          }}>
          <ActivityIndicator />
        </View>
      );

    return (
      <View style={styles.container}>
        {this.props.isMenuVisible && (
          <Menu
            streams={this.props.streams}
            selectedStreamID={streamId || this.props.streams[0].id}
          />
        )}
        <ScrollView
          style={{ flex: 1, flexBasis: 'auto' }}
          contentContainerStyle={{ height: '100%' }}>
          <View style={{ flex: 1 }}>
            <StreamInfo
              stream={this.props.streamsById[streamId] || this.props.streams[0]}
            />
          </View>
          <View style={{ paddingTop: 35, paddingBottom: 7 }}>
            <Image
              resizeMode="contain"
              style={{
                flex: 1,
                flexBasis: 'auto',
                width: '100%',
                height: 175,
                opacity: 0.75,
                position: 'relative'
              }}
              source={require('../assets/wisp.svg')}
            />
          </View>
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    streams: StreamSelectors.getStreams(state.stream) || [],
    streamsById: state.stream.streamEntities,
    error: state.stream.error,
    fetching: state.stream.fetching
  };
};

//const HomeWithRouter = withRouter(Home);

export default connect(
  mapStateToProps,
  {
    fetchAllStreams
  }
)(Home);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexBasis: 'auto',
    flexShrink: 0,
    flexGrow: 1,
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    minHeight: 650
  },
  bold: {
    fontWeight: 'bold'
  },
  streamListItem: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    fontSize: 16
  },
  errorContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 25,
    paddingVertical: 15,
    borderRadius: 5
  },
  errorMessage: {
    color: '#333333',
    fontWeight: '500',
    fontSize: 20
  },
  userIcon: {
    width: 30,
    height: 30
  }
});
