import React, { useState, useEffect, useCallback, Component } from "react";
import {
  View,
  Image,
  StyleSheet,
  ScrollView,
  Alert,
  ActivityIndicator,
  Text,
  ImageBackground,
  Platform
} from "react-native";
import { Link } from "react-router-dom";
import fetchData from "./fetchData";
import { Feather } from "@expo/vector-icons";
import ReportMenu from "./components/ReportMenu";
import { connect } from "react-redux";
import { fetchAllStreams } from "./actions/streamActions";
import { StreamSelectors } from "./reducers/streamReducer";

export interface Props {
  streams: Array<any>;
  streamsById: any;
  fetchAllStreams(): any;
  isMenuVisible: boolean;
  onMenuPress(): any;
  loginUser(username: string, password: string): any;
  fetching: boolean;
  error: boolean;
}
export interface State {
  selectedStreamID: string;
}

function SelectedStreamInfo({ stream }) {
  return <ReportList apps={stream.reports} />;
}

function ReportList({ apps }) {
  return (
    <View>
      {apps.map(app => (
        <View style={styles.rowContainer} key={app.id}>
          {/* <Link to={"/reportMetadata/name=" + app.name} style={{textDecoration: "none"}} > */}
          <Link
            to={{ pathname: "/reportMetadata", state: { name: app.name } }}
            style={{ textDecoration: "none" }}
          >
            <Text style={styles.bulletItem}>{"\u2B24"}</Text>
            <Text style={styles.textItem}>{app.name}</Text>
          </Link>
        </View>
      ))}
    </View>
  );
}

class ReportCatalogue extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      selectedStreamID: null
    };
  }

  componentDidMount() {
    this.props.fetchAllStreams();
  }

  render() {
    if (this.props.fetching === false && this.props.error === true)
      return (
        <View
          style={{
            flex: 1,
            flexBasis:'auto',
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <View style={styles.errorContainer}>
            <Feather
              style={{
                marginBottom: 15
              }}
              name="alert-circle"
              size={27}
              color="grey"
            />
            <Text
              style={[
                styles.errorMessage,
                {
                  fontWeight: "bold",
                  marginBottom: 15,
                  color: "#e74c3c"
                }
              ]}
            >
              An error occurred
            </Text>
            <Text style={styles.errorMessage}>Could not get streams data</Text>
          </View>
        </View>
      );
    if (this.props.streams.length == 0)
      return (
        <View
          style={{
            flex: 1,
            flexBasis:'auto',
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 650
          }}
        >
          <ActivityIndicator />
        </View>
      );
    return (
      <View style={styles.container}>
        {this.props.isMenuVisible && (
          <ReportMenu
            streams={this.props.streams}
            selectedStreamID={this.state.selectedStreamID}
            setSelectedStreamID={streamId => {
              if (Platform.OS != "web") this.props.onMenuPress();
              this.setState({ selectedStreamID: streamId });
            }}
          />
        )}
        <ScrollView style={{ flex: 1,                     flexBasis:'auto' }}>
          <View style={styles.rightContainer}>
            <View style={styles.innerContainer}>
              <SelectedStreamInfo
                stream={
                  this.props.streamsById[this.state.selectedStreamID] ||
                  this.props.streams[0]
                }
              />
            </View>
          </View>
          {/* <View style={{ paddingTop: 35, paddingBottom: 7 }}>
            <Image
              resizeMode="contain"
              style={{
                flex: 1,
                width: "100%",
                height: 175,
                opacity: 0.75,
                position: "relative"
              }}
              source={require("../assets/background/report_bg_image.png")}
            />
          </View> */}
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    streams: StreamSelectors.getStreams(state.stream) || [],
    streamsById: state.stream.streamEntities,
    error: state.stream.error,
    fetching: state.stream.fetching
  };
};

export default connect(
  mapStateToProps,
  {
    fetchAllStreams
  }
)(ReportCatalogue);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexBasis:'auto',
    flexDirection: "row",
    minHeight: 650
  },
  bold: { fontWeight: "bold" },

  menuContainer: {
    width: 225,
    borderRightWidth: 1,
    borderRightColor: "grey",
    paddingTop: 15
  },
  menuItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 15
  },
  menuItemText: {
    fontSize: 16,
    color: "#21428B",
    fontFamily: "Arial"
  },
  menuHeaderContainer: {
    marginTop: 20,
    marginBottom: 10,
    marginHorizontal: 25
  },
  menuItemsHeader: {
    backgroundColor: "#21428B",
    borderRadius: 25,
    color: "#FFFFFF",
    paddingVertical: 5,
    paddingHorizontal: 25,
    fontFamily: "Arial"
  },
  innerItemsHeader: {
    backgroundColor: "#407cee",
    borderRadius: 25,
    color: "#FFFFFF",
    marginBottom: 10,
    paddingVertical: 5,
    paddingHorizontal: 25
  },

  menuItemIcon: {
    width: 45,
    height: 45,
    paddingRight: 5
  },
  streamListItem: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    fontSize: 16
  },
  selectedStreamListItem: {
    backgroundColor: "rgba(0,0,0,.05)"
  },
  userIcon: {
    width: 30,
    height: 30
  },
  reportMenuContainer: {
    width: 270,
    borderRightWidth: 1,
    borderRightColor: "grey",
    paddingTop: 15
  },
  innerContainer: {
    flex: 1,
    flexBasis:'auto',
    //flexDirection: 'column',
    justifyContent: "center",
    paddingVertical: 150,
    paddingHorizontal: 350
  },
  rowContainer: {
    flexDirection: "row",
    paddingBottom: 7
  },

  bgImage: {
    zIndex: -1,
    position: "absolute",
    width: "100%",
    height: "100%",
    bottom: 0
  },
  textItem: {
    color: "#407cee",
    fontSize: 25,
    paddingBottom: 20
  },
  bulletItem: {
    color: "#fab130",
    padding: 10,
    fontSize: 15
  },
  selectedItemText: {
    color: "#E57230"
  },
  bottom: {
    flex: 1,
    flexBasis:'auto',
    justifyContent: "flex-end",
    marginBottom: 36
  },
  errorContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 25,
    paddingVertical: 15,
    borderRadius: 5
  },
  errorMessage: {
    color: "#333333",
    fontWeight: "500",
    fontSize: 20
  },
  rightContainer: {
    flex: 1,
    flexBasis:'auto',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  }
});
