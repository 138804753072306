import {
    REGION,
    IDENTITY_POOL_ID,
    USER_POOL_ID,
    CLIENT_ID,
} from 'react-native-dotenv';

export const Config = {
    userPoolId: USER_POOL_ID,
    userPoolClientId: CLIENT_ID,
    identityPoolId: IDENTITY_POOL_ID,
    awsRegion: REGION,
    logLevel: 'debug',
    mqttDebugLevel: 'true',
};
