import React, { useState, useEffect, useCallback, useMemo } from 'react';

const defaultToken = {
  token: '',
  refetchToken: () => {},
};

const uri = {
  getBobjToken: 'https://cxi48xn1me.execute-api.us-east-2.amazonaws.com/Dev',
};

const bobjTokenPostArgs = {
  method: 'POST',
  headers: {
    // Accept: 'application/json',
    // 'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    userID: 'SHIVA',
    password: 'Temp12345',
  }),
};

async function fetchToken() {
  const tokenRes = await fetch(uri.getBobjToken, bobjTokenPostArgs);
  const tokenXmlString = await tokenRes.json();
  //const tokenXmlString = '';
  const match = tokenXmlString.match(
    /<attr name="logonToken" type="string">(.*)<\/attr>/
  );
  console.log('tokenXmlString', { tokenXmlString, match });
  if (match) {
    const token = match[1];
    return token;
  }
  return null;
}

function useBobjToken() {
  const [token, setToken] = useState(null);
  const refetchToken = useCallback(async () => {
    const token = await fetchToken();
    setToken(token);
  }, []);
  useEffect(() => {
    refetchToken();
  }, [refetchToken]);
  const value = useMemo(() => ({ token, refetchToken }), [token, refetchToken]);
  return value;
}

export const BobJTokenContext = React.createContext(defaultToken);

export function BobJTokenParent(props) {
  const value = useBobjToken();
  return (
    <BobJTokenContext.Provider value={value}>
      {props.children}
    </BobJTokenContext.Provider>
  );
}
