import React from 'react';
import InsightApp from './src/Root';
//import LayoutParent from './src/LayoutParent';
//import { BobJTokenParent } from "./src/components/BobjTokenContext";
//import * as log from "loglevel";

import { Provider } from 'react-redux';
import configureStore from './src/store/configureStore';
import { DimensionProvider } from 'react-native-dimension-aware';
import { View } from 'react-native';

const store = configureStore;
//log.setLevel("debug");

console.disableYellowBox = true;

function App() {
  return (
    <DimensionProvider>
      <Provider store={store}>
        <InsightApp />
      </Provider>
    </DimensionProvider>
  );
}

export default App;
