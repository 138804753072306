import React from 'react';

import PropTypes from 'prop-types';

import {
  Platform,
  StyleSheet,
  Text,
  TouchableHighlight,
  TouchableNativeFeedback,
  TouchableWithoutFeedback,
  View
} from 'react-native';

const Touchable = Platform.select({
  android: TouchableNativeFeedback,
  default: TouchableHighlight
});

const MenuItem = props => {
  const touchableProps =
    Platform.OS === 'android'
      ? { background: TouchableNativeFeedback.SelectableBackground() }
      : {};

  const { children, disabled, onPress, style } = props;
  const disabledTextColor = '#bdbdbd';

  return (
    <TouchableWithoutFeedback
      disabled={disabled}
      onPress={onPress}
      {...touchableProps}
      {...props}>
      <View style={[styles.container, style]}>
        <Text
          numberOfLines={1}
          style={[styles.title, disabled && { color: disabledTextColor }]}>
          {children}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 48,
    justifyContent: 'center',
    maxWidth: 248,
    minWidth: 124
  },
  title: {
    fontSize: 14,
    fontWeight: '400',
    paddingHorizontal: 16,
    textAlign: 'left'
  }
});

export default MenuItem;
