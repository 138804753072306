import {
  REGION,
  OAUTH_DOMAIN,
  IDENTITY_POOL_ID,
  USER_POOL_ID,
  CLIENT_ID,
  CLOUD_FRONT_URL
} from 'react-native-dotenv';

const urlOpener = async (url, redirectUrl) => {
  // On Expo, use WebBrowser.openAuthSessionAsync to open the Hosted UI pages.

  console.log(url);
  console.log(redirectUrl);
};

export default {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: IDENTITY_POOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    oauth: {
      domain: OAUTH_DOMAIN,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],

      redirectSignIn: `https://${CLOUD_FRONT_URL}`,
      redirectSignOut: `https://${CLOUD_FRONT_URL}/signout`,
      responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
};
