import * as Cognito from '../services/aws-cognito';
import * as log from 'loglevel';
import { AsyncStorage } from 'react-native';

import {
  LOGGED_IN_STATUS_CHANGED,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGIN_USER,
  AUTH_FORM_UPDATE,
  LOGOUT,
  SET_CURRENT_USER,
  AUTH_USER,
  AUTH_USER_SUCCESS,
  AUTH_USER_FAILED
} from './types';
import { Auth } from 'aws-amplify';

const signOutUserSuccess = dispatch => {
  dispatch({ type: LOGGED_IN_STATUS_CHANGED, loggedIn: null });
  dispatch({ type: LOGOUT });
};

export const handleSignOut = () => dispatch =>
  Auth.signOut()
    .then(async () => {
      await AsyncStorage.clear();
      signOutUserSuccess(dispatch);
    })
    .catch(err => console.log(err));

/*
 * After successful login
 * Store AWS credentials, provider and token in sessionStorage
 * Redirect to app
 *
 * @param {Function} dispatch
 * @param {object} user - user object returned from Cognito
 * @param {object} awsCredentials - object containing aws identity credentials
 * @param {string} awsCredentials.accessKeyId
 * @param {string} awsCredentials.secretAccessKey
 * @param {string} awsCredentials.sessionToken
 * @param {string} provider - type of identity provider (user_pool for now)
 * @param {string} token - token from identity provider
 * */

const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const authUserSuccess = (dispatch, user) => {
  const userAttributes = user.attributes;
  const fullName = userAttributes.email.slice(0, -11).split('.');
  const username = capitalize(fullName[0]) + ' ' + capitalize(fullName[1]);

  const userData = {
    ...user,
    username
  };

  dispatch({ type: AUTH_USER_SUCCESS, user: userData });
};

const authUserFail = (dispatch, error) =>
  dispatch({ type: AUTH_USER_FAILED, error });

/*
 * Clears any cached data from previous logins managed by AWS SDK
 * */
const clearCognitoLocalStorage = () => {
  AsyncStorage.clear();
};

export const authUser = () => dispatch => {
  dispatch({ type: AUTH_USER });
  setTimeout(() => {
    Auth.currentAuthenticatedUser({bypassCache: true})
        .then(user => {
          console.log(user)
          authUserSuccess(dispatch, user)
        })
        .catch(error => {
          console.log(error)
          authUserFail(dispatch, error)
        });
  }, 1000)
};

export const loggedInStatusChanged = loggedIn => ({
  type: LOGGED_IN_STATUS_CHANGED,
  loggedIn
});

export const setCurrentUser = (loggedIn, user) => ({
  type: SET_CURRENT_USER,
  loggedIn,
  user
});
