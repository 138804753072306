import { combineReducers } from 'redux';
//import {routerReducer} from 'react-router-redux';
import stream from './streamReducer';
import auth from './authReducer';

const rootReducer = combineReducers({
    auth,
    stream,
});

export default rootReducer;
