import React from 'react';
import Svg, { Defs, G, Path } from 'react-native-svg';

export default function VyaireLogo(props) {
    const width = props.width || 165;
    const height = props.height || 40;
    const viewBox = props.viewBox || '0 0 165 44';

    return (
        <Svg width={width} height={height} viewBox={viewBox}>
            <Defs>
                <Path id="a" d="M.04.622h3.027V3.76H.041z" />
                <Path id="c" d="M0 .658h34.354V28.92H0z" />
                <Path id="e" d="M0 44h163.9V1.1H0z" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <Path
                    d="M103.4 29.7c-1.216 0-2.2-.98-2.2-2.189V3.29c0-1.21.984-2.189 2.2-2.189 1.216 0 2.2.98 2.2 2.189v24.22a2.194 2.194 0 0 1-2.2 2.189m32.121-16.489c1-4.42 4.955-7.729 9.668-7.729 4.714 0 8.67 3.31 9.668 7.728h-19.336zm23.953 1.857C159.297 7.335 152.957 1.1 145.19 1.1c-7.88 0-14.289 6.415-14.289 14.3 0 7.885 6.41 14.3 14.289 14.3 4.651 0 9.025-2.277 11.701-6.09a2.192 2.192 0 0 0-.533-3.052 2.188 2.188 0 0 0-3.05.534 9.933 9.933 0 0 1-8.118 4.227c-4.712 0-8.665-3.31-9.664-7.728h21.786a2.19 2.19 0 0 0 2.163-2.524zm-.134-13.623h-.94V1.1h2.2v.344h-.94V4.4h-.32z"
                    fill="#1E4488"
                />
                <G transform="translate(161.7)">
                    {/* <mask id="b" fill="#fff"><use xlink:href="#a"></use></mask> */}
                    <Path
                        fill="#1E4488"
                        mask="url(#b)"
                        d="M.04.622h.361l1.153 1.712L2.706.622h.361V3.76H2.71V1.22L1.558 2.9H1.54L.39 1.223V3.76H.04z"
                    />
                </G>
                <Path
                    d="M113.26 29.7c-1.194 0-2.16-.98-2.16-2.189V12.483c0-6.277 5.04-11.383 11.235-11.383h6.405c1.193 0 2.16.98 2.16 2.189s-.967 2.189-2.16 2.189h-6.405c-3.812 0-6.914 3.143-6.914 7.005V27.51c0 1.21-.967 2.189-2.16 2.189M82.5 25.318c-5.469 0-9.918-4.448-9.918-9.918 0-5.47 4.45-9.918 9.918-9.918 5.469 0 9.918 4.448 9.918 9.918 0 5.47-4.45 9.918-9.918 9.918zM96.8 15.4c0-7.885-6.415-14.3-14.3-14.3-7.885 0-14.3 6.415-14.3 14.3 0 7.885 6.415 14.3 14.3 14.3a14.24 14.24 0 0 0 9.918-4.014v1.82a2.19 2.19 0 0 0 4.382 0V15.4z"
                    fill="#1E4488"
                />
                {/* <mask id="d" fill="#fff"><use xlink:href="#c"></use></mask> */}
                <Path
                    d="M17.177 22.591L26.84 5.207c1.391-2.502 2.95-4.55 7.514-4.55L19.34 27.67c-.383.687-1.285 1.25-2.163 1.25-.878 0-1.78-.563-2.163-1.25L0 .658c4.564 0 6.122 2.047 7.514 4.55l9.663 17.383z"
                    fill="#1E4488"
                />
                <Path
                    d="M69.3 1.1c-4.53 0-6.077 2.041-7.458 4.537L52.25 22.968 42.658 5.637C41.278 3.14 39.73 1.1 35.2 1.1l14.577 26.34-5.512 9.96c4.53 0 6.078-2.042 7.458-4.536L69.3 1.1z"
                    fill="#1E4488"
                />
                <Path
                    d="M74.056 44l-.273-4.374c-.028-.52-.066-1.057-.084-1.632a19.54 19.54 0 0 1-.5 1.603l-1.223 3.583c-.255.763-.311.82-1.036.82-.79 0-.837-.038-1.09-.83l-1.197-3.62a28.582 28.582 0 0 1-.47-1.556 41.65 41.65 0 0 1-.085 1.574l-.3 4.432H67.1l.48-6.6h.734c.367 0 .443.047.537.349l1.713 5.28c.113.358.132.367.376.367.226 0 .236-.018.358-.367l1.798-5.27c.103-.321.207-.359.518-.359h.715L74.8 44h-.744zm11.439-5.996v2.281h3.228v.604h-3.228v2.507H89.1V44h-4.4v-6.6H89v.604zm14.306 5.374h1.824c1.783 0 2.064-.17 2.064-2.697 0-2.49-.281-2.659-2.064-2.659H99.8v5.356zm1.794-5.978c2.425 0 2.905.17 2.905 3.28 0 3.112-.47 3.32-2.905 3.32H99v-6.6h2.595z"
                    fill="#00AEEF"
                />
                {/* <mask id="f" fill="#fff"><use xlink:href="#e"></use></mask> */}
                <Path
                    fill="#00AEEF"
                    mask="url(#f)"
                    d="M113.3 44h.723v-6.6h-.723zm15.4-6.128v.205l-.053.055c-.255-.065-.81-.12-1.628-.12-1.857 0-1.998.13-1.998 2.688 0 2.558.141 2.688 1.998 2.688.818 0 1.33-.055 1.628-.139l.053.047v.213c0 .333-.282.491-1.875.491-2.234 0-2.525-.315-2.525-3.3s.291-3.3 2.56-3.3c1.611 0 1.84.158 1.84.472m13.11.49c-.095-.345-.12-.364-.456-.364-.335 0-.37.019-.464.364l-.86 3.16h2.632l-.851-3.16zm-1.954 3.768L139.35 44h-.749l1.636-5.778c.223-.776.343-.822 1.127-.822.775 0 .878.046 1.102.813L144.1 44h-.775l-.499-1.87h-2.97zm19.644 1.266V44h-4.4v-6.6h.791v5.996z"
                />
            </G>
        </Svg>
    );
}
