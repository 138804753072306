import React, { Component } from "react";
import { View, ActivityIndicator } from "react-native";
import {
    HashRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";
import { connect } from "react-redux";
import App from "./App";
import {
    authUser,
    loggedInStatusChanged,
    setCurrentUser
} from "./actions/authAction";
import { PrivateRoute } from "./PrivateRoute";
import Login from "./Login";

import Amplify, { Auth, Hub, Cache } from "aws-amplify";
import config from "./amplify-config";

Amplify.configure(config);

export interface Props {
    loggedIn: boolean;
    setCurrentUser(boolean, any): any;
    authUser(): any;
    loading: boolean;
}
export interface State {}

const ProppedRoute = ({ render: C, props: childProps, ...rest }) => (
    <Route {...rest} render={rProps => <C {...rProps} {...childProps} />} />
);

const Routes = ({ childProps }) => (
    <Switch>
        <ProppedRoute exact path="/login" render={Login} props={childProps} />
        <PrivateRoute path="/" render={App} props={childProps} />
    </Switch>
);

class Root extends Component<Props, State> {
    constructor(props) {
        super(props);

        Hub.listen("auth", data => {
            if (data && data.payload) {
                console.log(data.payload.event,data);
            }

            const { payload } = data;

            if (data.payload.event === "signIn") {
                this.props.authUser()
            }
        });

        // Auth.currentAuthenticatedUser()
        //     .then(user => console.log(user))
        //     .catch(error => console.log('Not signed in'));

        this._bootstrapAsync();
    }

    // eslint-disable-next-line no-underscore-dangle
    async _bootstrapAsync() {
        const federatedInfo = await Cache.getItem('federatedInfo');

        console.log('federatedInfo:', federatedInfo);

        this.props.authUser()
    }

    render() {
        const { loggedIn, loading } = this.props;

        console.log(loggedIn);

        const childProps = {
            isLoggedIn: loggedIn,
            onUserSignIn: this.props.authUser,
            loading: this.props.loading
        };

        if (loading === true || loggedIn === null) {
            return (
                <View
                    style={{
                        flex: 1,
                        flexBasis:'auto',
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <ActivityIndicator />
                </View>
            );
        }

        return (
            <Router hashType="noslash">
                <Route path="/">
                    {loggedIn ? <Redirect to="/streams" /> : <Redirect to="/login" />}
                </Route>
                <Routes childProps={childProps} />
                <Redirect from="/" to="/streams" />
            </Router>
        );
    }
}

const mapStateToProps = state => ({
    loggedIn: state.auth.loggedIn,
    user: state.auth.user,
    loading: state.auth.loading
});

export default connect(
    mapStateToProps,
    { authUser }
)(Root);
