import React, { Component } from 'react';
import { Auth, Cache } from 'aws-amplify';
import { Authenticator, withOAuth } from 'aws-amplify-react';

import {
  Text,
  View,
  KeyboardAvoidingView,
  StyleSheet,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  AsyncStorage
} from 'react-native';

import { Feather } from '@expo/vector-icons';
import VyaireLogo from './components/VyaireLogo';
import { Redirect } from 'react-router-dom';

import Hyperlink from 'react-native-hyperlink';
export interface Props {
  loading: boolean;
  location: any;
  error: string;
  onUserSignIn(): any;
}
export interface State {
  user: any;
  customState: any;
}

class Login extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      customState: null
    };

    AsyncStorage.getAllKeys((err, keys) => {
      AsyncStorage.multiGet(keys, (err, stores) => {
        stores.map((result, i, store) => {
          // get at each store's key/value so you can work with it
          let key = store[i][0];
          let value = store[i][1];

          console.log(`${key} : ${value}`);
        });
      });
    });
  }

  handleStateChange = (state, data) => {
    console.log('state change');
    console.log(state, data);
    if (state === 'signedIn') {
      this.props.onUserSignIn();
    }
  };

  render() {
    const { error } = this.props;

    const { user } = this.state;

    console.log('user', user);
    console.log('user', this.props.loading);

    if (user) {
      return <Redirect to="/streams" />;
    }

    if (this.props.loading == true) {
      return (
        <View
          style={{
            flex: 1,
            flexBasis: 'auto',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <ActivityIndicator />
        </View>
      );
    }

    return (
      <View
        style={{
          flex: 1,
          flexBasis: 'auto',
          height: '100%',
          backgroundColor: '#f0f0f0',
          paddingHorizontal: 70,
          paddingVertical: 40
        }}>
        <Authenticator
          hideDefault={true}
          onStateChange={this.handleStateChange}>
          <View
            style={{
              flex: 1,
              flexBasis: 'auto',
              height: '100%',
              shadowColor: '#000',
              backgroundColor: '#f0f0f0'
            }}>
            <View style={styles.containerView}>
              <View style={styles.loginScreenContainer}>
                <View style={styles.loginFormView}>
                  <View style={styles.logoContainer}>
                    <VyaireLogo />
                    <Image
                      resizeMode="contain"
                      source={require('../assets/logo/insight.png')}
                      style={styles.insightLogo}
                    />
                  </View>
                  {error && (
                    <View style={styles.errorContainer}>
                      <Feather
                        style={{
                          marginLeft: 25,
                          marginRight: 15
                        }}
                        name="x-circle"
                        size={16}
                        color="grey"
                      />
                      <Text style={styles.errorMessage}>{error}</Text>
                    </View>
                  )}
                  <TouchableOpacity
                    style={styles.loginButton}
                    onPress={() =>
                      Auth.federatedSignIn({
                        // @ts-ignore
                        provider: 'AzureAD'
                      })
                    }>
                    <Text style={styles.loginButtonTitle}>
                      Sign In - Insight Portal
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 25,
                alignItems: 'center',
                flexWrap: 'wrap',
                paddingHorizontal: 50
              }}>
              <Hyperlink
                linkStyle={{ color: '#007bff' }}
                onPress={url => (window.location.href = url)}>
                <Text>
                  Need help? Call 1-872-757-0400 or email ConnectIT@vyaire.com
                </Text>
              </Hyperlink>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <View
                  style={{
                    borderStyle: 'solid',
                    borderLeftWidth: 1,
                    borderLeftColor: '#000000',
                    height: 15,
                    paddingRight: 15
                  }}
                />
                <View style={{ paddingRight: 12 }}>
                  <VyaireLogo width={75} height={45} viewBox="0 0 165 35" />
                </View>
                <Hyperlink
                  linkStyle={{ color: '#007bff' }}
                  onPress={url => window.open(url, '_blank')}>
                  <Text
                    style={{
                      paddingRight: 15,
                      fontSize: 16,
                      marginBottom: 3
                    }}>
                    vyaire.com
                  </Text>
                </Hyperlink>
                <View
                  style={{
                    borderStyle: 'solid',
                    borderLeftWidth: 1,
                    borderLeftColor: '#000000',
                    height: 15,
                    paddingRight: 25
                  }}
                />
                <Text>Vyaire Medical, Inc. All Rights Registered</Text>
              </View>
            </View>
          </View>
        </Authenticator>
      </View>
    );
  }
}

export default withOAuth(Login);

const styles = StyleSheet.create({
  containerView: {
    flex: 1,
    flexBasis: 'auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 25
  },
  logoContainer: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  insightLogo: {
    width: 170,
    height: 150
  },
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F9CFC7',
    paddingVertical: 15,
    marginHorizontal: 15,
    borderRadius: 5
  },
  errorMessage: {
    color: '#333333',
    fontWeight: '500',
    fontSize: 16
  },
  loginScreenContainer: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    flex: 1,
    flexBasis: 'auto',
    maxWidth: 550,
    paddingVertical: 60,
    backgroundColor: '#FFF',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },

  loginFormView: {
    flex: 1,
    flexBasis: 'auto'
  },
  loginFormTextInput: {
    height: 43,
    fontSize: 14,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#eaeaea',
    backgroundColor: '#fafafa',
    paddingLeft: 10,
    marginLeft: 15,
    marginRight: 15,
    marginTop: 15,
    marginBottom: 5
  },
  loginButton: {
    backgroundColor: '#0756B8',
    borderRadius: 5,
    height: 45,
    marginTop: 25,
    marginBottom: 25,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 124
  },
  loginButtonTitle: {
    color: '#FFFFFF',
    fontSize: 18
  },
  fbLoginButton: {
    height: 45,
    marginTop: 10,
    backgroundColor: 'transparent'
  }
});
