import {
    CognitoUserPool,
    CognitoUser,
    CognitoUserAttribute,
    AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import { AsyncStorage } from 'react-native';
import * as AWS from 'aws-sdk/global';
import * as log from 'loglevel';

import { Config } from './aws-config';
const jwtDecode = require('jwt-decode');

//log.debug(Config);

const userPool = new CognitoUserPool({
    UserPoolId: Config.userPoolId,
    ClientId: Config.userPoolClientId,
});

export const getUserInfo = accessToken =>
    fetch(
        'https://devinsight.auth.us-east-2.amazoncognito.com/oauth2/userInfo',
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }
    ).then(res => res.json());

const getCurrentUser = () => userPool.getCurrentUser();

/*
 * Fetch JWT token from current session
 *
 * @param {CognitoUser} currentUser
 * @returns {Promise<string>} - Promise resolves with the JWT session ID token
 * */
//
// const id_token= 'eyJraWQiOiIrSjE4M2hTdGh6ZUt2Mnowdk9wenVJbHpEb3E3aU5ma2RzZmRCbDhYS1pjPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoiN1Q3aTY3RGszdUc2ekNla19YZ0hZZyIsInN1YiI6ImRlZWE0YTQyLTk4ZmMtNDM1OS04ZmM1LWU3YWE0N2RkMDFhYiIsImNvZ25pdG86Z3JvdXBzIjpbInVzLWVhc3QtMl8yODdHOUpEN1lfQXp1cmVBRCJdLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0yXzI4N0c5SkQ3WSIsImNvZ25pdG86dXNlcm5hbWUiOiJBenVyZUFEX0FiZHVsbGFoaS5NYWhhbWVkQFZ5YWlyZS5jb20iLCJnaXZlbl9uYW1lIjoiQWJkdWxsYWhpIiwiYXVkIjoiNjQ5bmgyMGY0M3AwbDgyZzFocnJ1bDZoMXQiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJBYmR1bGxhaGkuTWFoYW1lZEBWeWFpcmUuY29tIiwicHJvdmlkZXJOYW1lIjoiQXp1cmVBRCIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJodHRwczpcL1wvc3RzLndpbmRvd3MubmV0XC82N2NmNGFkNC02YTFhLTRhMDEtOWRmZS1hZjk0YzFhZGJjMDdcLyIsInByaW1hcnkiOiJ0cnVlIiwiZGF0ZUNyZWF0ZWQiOiIxNTcwNjQ5MDcxNzIxIn1dLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTU3MDk4Nzc5OSwibmFtZSI6IkFiZHVsbGFoaS5NYWhhbWVkQFZ5YWlyZS5jb20iLCJleHAiOjE1NzA5OTEzOTksImlhdCI6MTU3MDk4Nzc5OSwiZW1haWwiOiJBYmR1bGxhaGkuTWFoYW1lZEB2eWFpcmUuY29tIn0.AYVWtfqC-GLDAKWM14axAbnhEvv7eZInpIpZLtXBkP7iEoay3cH9AAnJCTMY9YeDcAA-ZU7Q4I4TpNpzaTwJ1zq0DIFAxXkQtu-zvPWyhnHUeV7TaY6jV385eC_YAEjCHHHDAkREgO6qS_xSkRaKWPPHHs1_pE-WzBDEFUsHIuwcxW9b6m_zFWa5vogkZ24yiIZWyrW7BpHu3_mZBmKPbZYOjXWmbPfgOVo7Oj_xMoMzUscWypb3_X9u-mI4relBobjzsg9SeBqIqwIUDur4pUR0Bxkfaev1w6BCbdPZ5hdFk7lnts-tRFsTbw_SLr3zPMdTvRzWqTE6nOAQGbaSnA'
// const access_token = 'eyJraWQiOiJTTXBnVkxTVWs3RmM2SGFGSzFqVHlXNlVvZTNzbnFSWDdtRHUzblwvQ0JwRT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJkZWVhNGE0Mi05OGZjLTQzNTktOGZjNS1lN2FhNDdkZDAxYWIiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTJfMjg3RzlKRDdZX0F6dXJlQUQiXSwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImF1dGhfdGltZSI6MTU3MDk4Nzc5OSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMi5hbWF6b25hd3MuY29tXC91cy1lYXN0LTJfMjg3RzlKRDdZIiwiZXhwIjoxNTcwOTkxMzk5LCJpYXQiOjE1NzA5ODc3OTksInZlcnNpb24iOjIsImp0aSI6IjFiOWFiOWI5LTkxNDYtNDcwNC1iMDA3LTA5OGM3MmJjNGE5ZCIsImNsaWVudF9pZCI6IjY0OW5oMjBmNDNwMGw4MmcxaHJydWw2aDF0IiwidXNlcm5hbWUiOiJBenVyZUFEX0FiZHVsbGFoaS5NYWhhbWVkQFZ5YWlyZS5jb20ifQ.CAeZ9oCqgqXTHNVPntT3Vf_m7fgzDw0L-P7ZATkmSnGF2_JnCPnN1Hd7nChj9KSurBj7BwEHhcmsSBsZYRZS_iA8iUrrjGl9OlrblkPpOk8uYpHXAWVS8l5jDmaRpQrTcRGYQxBrdzMeGH_h4ShYwG8MgGS7fKSMiHkp5XLzpx-18nUenkfmYqHWzlXkWAcvl7DCzAGbx-D2LItzPeZcKLO8pEH4tBO4Ffo6CMyMXRtGFQChBIEuFRHqRH7OuIDrR2ryz8WKDcZEC99XqHE-Cr-VlDPsg4-8y-4-prpvulHWAmjRbAZeBvqKMoZOZ2TAsqu96PnznnGE59ypnPPARw'
// const expires_in = 3600
// const token_type = 'Bearer'

const getUserToken = currentUser =>
    new Promise((resolve, reject) => {
        currentUser.getSession((err, session) => {
            if (err) {
                reject(err);
                return;
            }
            resolve(session.getIdToken().getJwtToken());
        });
    });

/*
 * Fetch AWS credentials
 *
 * @param {string} token - Cognito User Pool token or third party
 * @param {string} provider - authenticated provider
 * @returns {Promise<object>} - Object containing accessKeyId, secretAccessKey, sessionToken
 * */

export const getAwsCredentials = (token, provider) =>
    new Promise((resolve, reject) => {
        let providerKey = '';

        switch (provider) {
            case 'user_pool':
                providerKey = `cognito-idp.${Config.awsRegion}.amazonaws.com/${Config.userPoolId}`;
                break;
            default:
                break;
        }

        AWS.config.region = Config.awsRegion;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: Config.identityPoolId,
            Logins: {
                [providerKey]: token,
            },
        });

        AWS.config.credentials.get(error => {
            if (error) {
                reject(error);
            }

            const {
                accessKeyId,
                secretAccessKey,
                sessionToken,
            } = AWS.config.credentials;
            const credentialSubset = {
                accessKeyId,
                secretAccessKey,
                sessionToken,
            };
            resolve(credentialSubset);
        });
    });

/*
 * Fetches user details from Cognito User Pool
 *
 * @param {string} username
 * @returns {Promise<object>} - Promise object represents mapping of attribute name to attribute value
 * */

const buildUserObject = username =>
    new Promise((resolve, reject) => {
        const cognitoUser = getCurrentUser();
        cognitoUser.getSession(sessionErr => {
            if (sessionErr) {
                reject(sessionErr);
            }

            cognitoUser.getUserAttributes((err, result) => {
                if (err) {
                    reject(err);
                }

                const user = {};
                for (let i = 0; i < result.length; i += 1) {
                    user[result[i].getName()] = result[i].getValue();
                }
                user.username = username;
                resolve(user);
            });
        });
    });

/*
 * Authenticate user using username and password
 *
 * @param {string} username
 * @param {string} password
 * @returns {Promise<CognitoUserSession>} - User session of authenticated user
 * */

const authenticateUser = (username, password) =>
    new Promise((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        });

        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: result => {
                resolve(result);
            },
            onFailure: error => {
                reject(error);
            },
        });
    });

/*
 * Checks if user is authenticated
 *
 * @returns {bool} - Whether the cached credentials are valid
 * */

export const authUser = async () => {
    if (
        AWS.config.credentials &&
        Date.now() < AWS.config.credentials.expireTime - 60000
    ) {
        return true;
    }

    const provider = await AsyncStorage.getItem('provider');
    let token = await AsyncStorage.getItem('providerToken');
    switch (provider) {
        case 'user_pool': {
            const currentUser = getCurrentUser();
            token = await getUserToken(currentUser);
            break;
        }
        default:
            return false;
    }

    await getAwsCredentials(token, provider);

    return true;
};

/*
 * Retrieve last Cognito Identity ID that was cached by AWS SDK
 *
 * @return {string} - Cognito Identity ID
 * */

export const getIdentityId = () => {
    const identityId = AWS.config.credentials.identityId;
    log.debug('principal', identityId);
    return identityId;
};

/*
 * Clears the cached Cognito ID associated with the currently configured identity pool ID
 * */

/*export const clearCachedId = () => {
  AWS.config.credentials.clearCachedId();
};*/

/*
 * Login to Amazon Cognito using username and password
 *
 * @param {string} username
 * @param {string} password
 * @returns {Promise}
 * */

export const loginUser = (username, password) =>
    new Promise((resolve, reject) => {
        authenticateUser(username, password)
            .then(cognitoUserSession => {
                const token = cognitoUserSession.getIdToken().getJwtToken();
                const credentialsPromise = getAwsCredentials(
                    token,
                    'user_pool'
                );
                const userPromise = buildUserObject(username);
                return Promise.all([credentialsPromise, userPromise]);
            })
            .then(values => {
                const awsCredentials = values[0];
                const user = values[1];
                const userData = Object.assign(
                    { awsCredentials },
                    { userObj: user }
                );
                resolve(userData);
            })
            .catch(err => {
                log.error(err);
                reject(err);
            });
    });

/*
 * Logout of Cognito
 *
 * @returns {Promise}
 * */

export const logoutUser = () =>
    new Promise(resolve => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            log.debug('User signing out of user pool');
            cognitoUser.signOut();
        } else {
            log.debug('User signing out of federated identity');
        }
        resolve();
    });

/*
 * Register a user with Cognito
 *
 * @param {string} username
 * @param {string} password
 * @param {string} email
 * @returns {Promise<string>} - Object represents the username of the registered user
 * */

export const register = (username, password, email) =>
    new Promise((resolve, reject) => {
        const attributeList = [];
        const attributeEmail = new CognitoUserAttribute({
            Name: 'email',
            Value: email,
        });
        attributeList.push(attributeEmail);
        userPool.signUp(
            username,
            password,
            attributeList,
            null,
            (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data.user.getUsername());
                }
            }
        );
    });

const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const authUserToken = (idToken, accessToken) =>
    new Promise((resolve, reject) => {
        const credentialsPromise = getAwsCredentials(idToken, 'user_pool');
        const payload = jwtDecode(idToken);
        const fullName = payload.email.slice(0, -11).split('.');
        const username =
            capitalize(fullName[0]) + ' ' + capitalize(fullName[1]);

        const user = {
            username,
            email: payload.email,
            expiresTime: payload.exp * 1000,
        };

        credentialsPromise
            .then(awsCredentials => {
                const userData = Object.assign(
                    { awsCredentials },
                    { userObj: user }
                );
                resolve(userData);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
    });
