import React, { useContext } from 'react';
import { BobJTokenContext } from './BobjTokenContext';
import { Linking, Platform, TouchableOpacity } from 'react-native';

function ExternalLink({ href, children }) {
    if (!href) {
        return children;
    }

    const handleClick = () => Linking.openURL(href);

    if (Platform.OS == 'web')
        return (
            <a
                style={{ textDecorationLine: 'none' }}
                target="_blank"
                href={href}>
                {children}
            </a>
        );
    else
        return (
            <TouchableOpacity onPress={handleClick}>
                {children}
            </TouchableOpacity>
        );
}
function AppLink({ app, children }) {
    const bobjToken = useContext(BobJTokenContext);

    const uri = app.reportUri
        ? app.reportUri.replace(
            '<bobj_token>',
            encodeURIComponent(bobjToken.token)
        )
        : '';

    return <ExternalLink href={uri}>{children}</ExternalLink>;
}

export default AppLink;
